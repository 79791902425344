<template>
	<div>
		<b-card class="py-3">
			<div class="card-header d-flex justify-content-between px-0">
				<div>
					<h3>Account Streams</h3>
					<router-link :to="{ name: 'video' }">View All</router-link>
				</div>
				<b-button variant="primary" v-b-modal.createStream>New Stream</b-button>
			</div>
			<div class="card-body px-0">
				<ITCSpinner :loaded="loaded">
					<div v-if="streams.length == 0" class="text-center">
						<h4>You have no saved streams</h4>
						<b-button variant="primary" v-b-modal.createStream>Add Stream</b-button>
					</div>
					<div v-else>
						<b-table hover :items="streams" :fields="fields" tbody-tr-class="rowClass">
							<template #cell(admin)="data">
								<span v-if="data.item.admin_id == userId" class="text-center admin-icon" v-b-tooltip="'Admin'">
									<i class="flaticon-user-settings" />
								</span>
							</template>
							<template #cell(stream_name)="data">
								<router-link :to="{ name: 'video' }" class="pl-1">{{ data.value }}</router-link>
							</template>
							<template #cell(ant_id)="data">
								<span v-b-tooltip="'Click to copy'" class="clickable pl-1" @click="copyToClipboard(data.value)">{{ data.value }}</span>
							</template>
							<template #cell(users)="data">
								<div v-if="!data.value || data.value.length == 0" class="ml-1">
									<span class="label label-lg label-inline label-light-success clickable" @click="addUser(data.item)"> None </span>
								</div>
								<div v-else>
									<template v-for="user in data.value">
										<router-link :to="{ name: 'admineditaccount', params: { id: user.account_id } }" :key="user.account_id">
											<span
												:class="`label-light-${data.item.admin_id == user.account_id ? 'info' : 'primary'}`"
												class="label label-lg label-inline clickable mx-1"
											>
												{{ user.username }}
											</span>
										</router-link>
									</template>
								</div>
							</template>
							<template #cell(actions)="data">
								<div class="text-right">
									<span
										v-if="data.item.admin_id == userId"
										class="btn btn-icon btn-light edit-icon btn-sm mr-2"
										@click="openEditStream(data.item)"
										v-b-tooltip="'Edit Stream'"
									>
										<i class="flaticon-more-v6" />
									</span>
									<span
										v-if="data.item.admin_id == userId"
										class="btn btn-icon btn-light edit-icon btn-sm mr-2"
										@click="addUser(data.item)"
										v-b-tooltip="'Edit User Access'"
									>
										<i class="flaticon-user" />
									</span>
									<span class="btn btn-icon btn-light delete-icon btn-sm" @click="deleteStream(data.item)" v-b-tooltip="'Remove Stream'">
										<i class="flaticon2-trash" />
									</span>
								</div>
							</template>
						</b-table>
					</div>
				</ITCSpinner>
			</div>
		</b-card>

		<!-- MODALS -->

		<!-- CREATE STREAM -->
		<b-modal id="createStream" :title="streamCreated ? 'Stream Successfully Created' : 'Add New Stream'" centered hide-footer @hide="modalHide">
			<b-form @submit.prevent="createStream" v-if="!streamCreated">
				<b-form-group label="Stream Name" label-for="streamName">
					<b-form-input type="text" id="streamName" v-model="streamName" class="text-center" />
				</b-form-group>
				<div class="text-center">
					<b-button variant="primary" type="submit" class="w-25">OK</b-button>
				</div>
			</b-form>
			<div v-else>
				<p>Name: {{ newStream.name }}</p>
				<p>
					Stream ID: <span @click="copyToClipboard(newStream.ant_id)" v-b-tooltip="'Click to copy'" class="clickable">{{ newStream.ant_id }}</span>
				</p>
				<p>
					Stream URL:
					<span @click="copyToClipboard('rtmp://streaming.itcglobal.com/RemoteVideo/')" v-b-tooltip="'Click to copy'" class="clickable">
						rtmp://streaming.itcglobal.com/RemoteVideo/
					</span>
				</p>
			</div>
		</b-modal>

		<!-- ADD USERS -->
		<b-modal id="addUser" title="Edit Users" size="lg" centered hide-footer @hide="hideUserModal">
			<h4>Users with Access</h4>
			<div v-if="addingUser.users && addingUser.users.length == 0">
				<span class="label label-lg label-inline label-light-success"> No Users</span>
			</div>
			<div v-else>
				<span
					v-for="(user, index) in addingUser.users"
					class="label label-lg label-inline label-light-primary mr-2 user-label"
					@click="removeUser(user, index)"
					:key="user.username"
					>{{ user.username }}</span
				>
			</div>
			<hr />
			<div class="d-flex justify-content-between align-items-center mt-7">
				<h4>Find Users</h4>
				<div class="col-6 pl-0">
					<b-form-input type="text" id="searchAccount" v-model="searchAccount" debounce="300" placeholder="Search" />
				</div>
			</div>
			<b-table
				:items="filteredAccounts"
				:fields="['username', 'name', 'email']"
				sort-by="username"
				per-page="15"
				:current-page="currentAccountsPage"
				tbody-tr-class="accountRowClass"
				@row-clicked="accountSelected"
			></b-table>
			<div class="d-flex justify-content-end" v-if="filteredAccounts.length > 15">
				<b-pagination class="pagination" v-model="currentAccountsPage" :total-rows="filteredAccounts.length" per-page="15" />
			</div>
			<div class="text-center">
				<b-button class="w-25" variant="primary" @click="saveUsers">Save</b-button>
			</div>
		</b-modal>

		<!-- EDIT STREAM -->
		<b-modal id="editStream" title="Edit Stream" centered hide-footer>
			<b-form @submit.prevent="editStream">
				<b-form-group label="Stream Name" label-for="streamName">
					<b-form-input type="text" id="streamName" v-model="streamName" class="text-center" />
				</b-form-group>
				<div class="text-center">
					<b-button variant="primary" type="submit" class="w-25">OK</b-button>
				</div>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'AccountStreams',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	props: ['username', 'userId'],
	data() {
		return {
			loaded: false,
			streams: [],
			fields: [
				{ key: 'admin', label: '', thStyle: { width: '10px' } },
				{ key: 'stream_name', label: 'Stream Name' },
				{ key: 'ant_id', label: 'Stream ID' },
				{ key: 'users', label: 'Users with Access' },
				{ key: 'actions', label: '' },
			],
			streamCreated: false,
			streamName: null,
			newStream: {
				name: null,
				id: null,
			},
			editingStream: null,
			addingUser: {},
			currentAccountsPage: 1,
			searchAccount: '',
			accounts: [],
		};
	},
	computed: {
		filteredAccounts() {
			if (!this.searchAccount) {
				return this.accounts;
			} else {
				return this.accounts.filter(account => {
					let search = this.searchAccount.toLowerCase();
					return (
						account.name.toLowerCase().includes(search) ||
						account.username.toLowerCase().includes(search) ||
						account.email.toLowerCase().includes(search)
					);
				});
			}
		},
	},
	methods: {
		createStream() {
			let payload = {
				username: this.username,
				stream: {
					name: this.streamName,
				},
			};
			this.$http.post(`streams`, payload).then(resp => {
				this.newStream.name = resp.data.name;
				this.newStream.ant_id = resp.data.streamId;
				this.streamCreated = true;
				this.streams.push({
					id: resp.data.itc_id,
					ant_id: resp.data.streamId,
					admin_id: resp.data.admin_id,
					stream_name: resp.data.name,
					users: [],
				});
			});
		},
		openEditStream(stream) {
			this.editingStream = stream;
			this.streamName = stream.stream_name;
			this.$bvModal.show('editStream');
		},
		editStream() {
			let payload = {
				username: this.username,
				stream: {
					name: this.streamName,
					ant_id: this.editingStream.ant_id,
				},
			};
			this.$http.put(`stream/${this.editingStream.id}`, payload).then(resp => {
				if ((resp.data.success = true)) {
					this.editingStream.stream_name = this.streamName;
					this.$bvModal.hide('editStream');
					this.editingStream = null;
					this.streamName = null;
				}
			});
		},
		modalHide() {
			this.streamCreated = false;
			this.streamName = null;
			this.newStream.name = null;
			this.newStream.id = null;
		},
		deleteStream(stream) {
			let message =
				stream.admin_id == this.userId
					? `Delete stream "${stream.stream_name}?" This will delete the stream for all users.`
					: `Delete this stream from user ${this.username}?`;
			this.$bvModal
				.msgBoxConfirm(message, {
					title: 'Confirm Delete',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
				})
				.then(val => {
					if (val) {
						if (stream.admin_id == this.userId) {
							this.$http.delete(`stream/${stream.id}`).then(resp => {
								if (resp.data.status == 204) {
									this.streams = this.streams.filter(s => s.id !== stream.id);
								}
							});
						} else {
							this.$http.delete(`stream/${stream.id}/${this.username}`).then(resp => {
								if (resp.data.status == 204) {
									this.streams = this.streams.filter(s => s.id !== stream.id);
								}
							});
						}
					}
				});
		},
		copyToClipboard(id) {
			navigator.clipboard.writeText(id);
		},
		addUser(stream) {
			this.addingUser = {
				id: stream.id,
				users: [],
			};
			let userids = stream.users.map(user => user.account_id);
			this.$bvModal.show('addUser');
			this.$http.get('accounts').then(resp => {
				this.accounts = resp.data.data.filter(account => {
					if (userids.includes(account.AccountID.toString())) {
						this.addingUser.users.push(account);
						return false;
					}
					return true;
				});
				this.totalRows = this.accounts.length;
			});
		},
		removeUser(user, index) {
			this.accounts.push(user);
			this.addingUser.users.splice(index, 1);
		},
		accountSelected(row, index) {
			this.addingUser.users.push(row);
			this.accounts = this.accounts.filter(account => account.AccountID !== row.AccountID);
		},
		saveUsers() {
			let ids = this.addingUser.users.map(user => user.AccountID.toString());
			this.$http.post(`streams/${this.addingUser.id}/users`, { username: this.username, users: ids }).then(resp => {
				this.hideUserModal();
				this.$bvModal.hide('addUser');
				this.load();
			});
		},
		hideUserModal() {
			this.addingUser = {};
			this.searchAccount = '';
		},
		load() {
			this.loaded = false;
			this.$http.get(`/streams/${this.username}`).then(resp => {
				this.streams = resp.data;
				this.loaded = true;
			});
		},
	},
	mounted() {
		this.load();
	},
	watch: {
		searchAccount() {
			this.currentAccountsPage = 1;
		},
		username() {
			this.load();
		},
	},
};
</script>

<style lang="scss" scoped>
.edit-icon i {
	color: $primary;
	cursor: pointer;
}
.delete-icon i {
	color: $danger;
	cursor: pointer;
}
.admin-icon i {
	color: $info;
}
.clickable:hover {
	cursor: pointer;
	color: $primary-hover;
}
::v-deep .rowClass td {
	padding: 5px 5px;
	vertical-align: middle;
}
::v-deep .accountRowClass:hover {
	cursor: pointer;
	color: $primary-hover;
}
.user-label:hover {
	color: $danger;
	background-color: $danger-light;
	cursor: pointer;
}
</style>
